import getXMLHttpRequest from './xhr';

/**
* Clear password
* <Element> targeted
*/

const clearPassword = (el) => {

    let container = el.parentElement,
        input     = container.querySelector('input'),
        attr      = input.getAttribute('type');

    (attr == 'password') ? input.setAttribute('type', 'text') : input.setAttribute('type', 'password');
}

/**
* Checker Password
* @param <Element> input[type=password]
*/

const checkPassword = (input) => {
    
    let field = input.parentElement,
        value       = input.value,
        lng         = value.length, 
        regexLength = 5,
        regexStrongLength = 8,
        regexHeavyLength = 16,
        regexSymb   = /[@|&|#|"|'|(|§|!|)|-|_|$|*|%|`|£|=|+|/|:|;|.|,|?|>|<|°|€]/g,
        regexMajNum    = /[A-Z0-9]/g,
        result = [];

    if (regexSymb.test(value))
        result.push(true)

    if (regexMajNum.test(value))
        result.push(true)

    if (lng >= regexStrongLength)
        result.push(true)

    if (lng >= regexHeavyLength)
        result.push(true)

    let resultLng = result.length;

    let trigger = (strOne, strTwo, strThree) => {
        if (field.classList.contains(strOne))
            field.classList.remove(strOne)

        if (field.classList.contains(strTwo))
            field.classList.remove(strTwo)

        if (!field.classList.contains(strThree))
            field.classList.add(strThree)
    }
    

    if (lng > 0) {
            
        if (resultLng == 0)
            trigger('medium', 'heavy', 'easy')

        if (resultLng == 1)
            trigger('easy', 'heavy', 'medium')

        if (resultLng > 2)
            trigger('easy', 'medium', 'heavy')

    } else {

        if (field.classList.contains('easy'))
            field.classList.remove('easy')

        if (field.classList.contains('medium'))
            field.classList.remove('medium')

        if (field.classList.contains('heavy'))
            field.classList.remove('heavy')
    }
}

/**
* Account settings
* @param <Element>
*/

const accountModifier = (el) => {

    let form  = el.parentElement.parentElement,
        p     = form.querySelectorAll('p:not(.no-hide)'),
        lngP  = p.length,
        input = form.querySelectorAll('.hidden'),
        lng   = input.length;
    
    for (let i=0; i<lng; i++)
        input[i].classList.remove('hidden')
    
    for (let i=0; i<lngP; i++)
        p[i].classList.add('hidden')
        
    el.classList.add('hidden-modifier')
    el.parentElement.nextElementSibling.classList.remove('none')
}

const getDelivery = (el) => {

    let xhr = getXMLHttpRequest()
    
    xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && (xhr.status === 200 || xhr.status === 0)) {

            let data = JSON.parse(xhr.response)

            for (let i in data) {
                let obj = data[i],
                    input = document.querySelector('input[name="' + i + '"]')

                if (input)
                   input.value = obj
            }
        }
    }

    xhr.open('GET', el.dataset.url, true)
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
    xhr.send()
    el.classList.add('trigger')

    console.log(document.querySelector('input[name="deliverymode"]'))
    console.log(document.querySelector('input[name="deliverymode"]').value)

    
    if (!document.querySelector('input[name="deliverymode"]').value || document.querySelector('input[name="deliverymode"]').value == 0) return 

    document.querySelector('.deliverymode__opt.trigger').classList.remove('trigger')
    document.querySelector('.deliverymode__content.trigger').classList.remove('trigger')
    document.querySelector('.deliverymode__opt').classList.add('trigger')
    document.querySelector('.deliverymode__content').classList.add('trigger')
    document.querySelector('input[name="deliverymode"]').value = '0'
}

export {clearPassword, checkPassword, accountModifier, getDelivery}