/**
* Localstorage
* @param <Element>, <Element>
*/

export default (window, document) => {

    let isModernBrowser = (
        'querySelector' in document &&
        'localStorage' in window &&
        'addEventListener' in window
        ),
    md5 = 'f12509041b4c86e195ecd1a889c74108',
    key = 'fonts',
    cache;

    if (!isModernBrowser) {
        return;
    }

    let insertFont = function (value) {
        let style = document.createElement('style');
        style.innerHTML = value;
        document.head.appendChild(style);
    };

    //PRE RENDER
    try {
        cache = window.localStorage.getItem(key);
        if (cache) {
            cache = JSON.parse(cache);
            if (cache.md5 == md5) {
                insertFont(cache.value);
            } else {
                window.localStorage.removeItem(key);
                cache = null;
            }
        }
    } catch (e) {
        return;
    }

    //POST RENDER
    if (!cache) {
        window.addEventListener('load', () => {
            let request = new XMLHttpRequest(),
            response;

            //TODO Vérifier l'url de la font à la mise en ligne
            
            request.open('GET', '/css/font/font.json', true);
            request.onload = function () {
                if (this.status == 200) {
                    try {
                        response = JSON.parse(this.response);
                        insertFont(response.value);
                        window.localStorage.setItem(key, this.response);
                    } catch(e) {
                        // LocalStorage is probably full
                    }
                }
            };
            request.send();
        });
    }
};