export default (container, slide, controller, auto = false, timing = 6000) => {

    let slideshow = document.querySelector('.' + container);

    if (!slideshow)
        return;

    let slider = {

        element: {
            container: slideshow,
            slide : slideshow.querySelectorAll('.' + slide),
            controller : slideshow.querySelectorAll('.' + controller)
        },

        index: 0,

        init: function () {

            if (auto)
                this.startInterval()
            this.bindUIEvents()
        },

        bindUIEvents: function () {

            this.element.container.addEventListener('click', function (event) {
                let el = event.target;

                if (el.classList.contains(controller))
                    slider.swipe(el)
            })

            this.element.container.addEventListener('keydown', function (event) {
                let keyCode = event.keyCode;

                if (keyCode == 37)
                    slider.swipe(false, -1)

                if (keyCode == 39)
                    slider.swipe(false, 1)
            })
        },

        swipe: function (btn = false, direction = false) {
            if (btn) {
                if (btn.getAttribute('aria-selected') == 'true')
                    return

                let data = btn.getAttribute('data-select')

                if (data == this.index)
                    return

                this.removeSlide()

                this.index = data

                if (auto)
                    return this.reloadProcess()
                else {
                    return this.selectSlide()
                }
            }

            if (direction) {
                
                this.removeSlide();

                // Si Number n'est pas précisé, Javascript traite les variables comme des strings
                this.index = Number(this.index) + Number(direction);
                
                if (this.index < 0)
                    this.index = this.element.slide.length - 1

                if (auto)
                    this.reloadProcess()
                else {
                    this.selectSlide()
                }

                return this.element.controller[this.index].focus()
            }

            this.removeSlide()

            this.index++

            this.selectSlide()
        },

        removeSlide: function () {
            this.element.slide[this.index].setAttribute('aria-hidden', 'true')
            this.element.controller[this.index].setAttribute('aria-selected', 'false')
            this.element.controller[this.index].setAttribute('tabindex', '-1')
        },

        selectSlide: function () {

            if (this.index == this.element.slide.length)
                this.index = 0

            this.element.slide[this.index].setAttribute('aria-hidden', 'false')
            this.element.controller[this.index].setAttribute('aria-selected', 'true')
            this.element.controller[this.index].setAttribute('tabindex', '0')
        }
    }

    if (auto) {

        slider.timing = timing;
        slider.interval = undefined;

        slider.startInterval = function () {
            this.interval = setInterval(function () {
                slider.swipe()
            }, this.timing)
        }

        slider.reloadProcess = function () {
            this.selectSlide()
            clearInterval(this.interval)
            this.startInterval()
        }
    }

    slider.init();
}