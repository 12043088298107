class ContactForm {

    constructor (container) {
        this.container = container
        this.submit    = container.querySelector('.submit-contact-form')
        this.subname   = container.querySelector('input[name="subname"]')
        this.name      = container.querySelector('input[name="name"]')
        this.company   = container.querySelector('input[name="company"]')
        this.siret     = container.querySelector('input[name="siret"]')
        this.email     = container.querySelector('input[name="email"]')
        this.textarea  = container.querySelector('textarea[name="content"]')
    }

    registerEvents () {
        this.submit.addEventListener('click', this.action.bind(this))
    }

    action (e) {
        e.preventDefault()
        
        this.handleAlert(this.subname, 'Votre prénom est obligatoire')
        this.handleAlert(this.name, 'Votre nom est obligatoire')
        this.handleAlert(this.email, 'Une adresse mail est obligatoire pour être recontacté(e)', 'Le format recquis est exemple@mail.com')
        this.handleAlert(this.textarea, 'Sans message, il n\'y a ien à lire')

        if (this.siret)
            this.handleAlert(this.siret, 'Votre numéro de SIRET est obligatoire.')

        if (this.company)
            this.handleAlert(this.company, 'Le nom de votre société est obligatoire.')

        if (this.container.querySelector('.alert') && !this.container.querySelector('.submit-fail')) {
            let msg = document.createElement('p')
            msg.className = 'submit-fail'
            msg.innerHTML = 'Un ou plusieurs champs sont vides ou incorrects'
            return this.submit.parentElement.appendChild(msg)
        }
            
        if (!this.container.querySelector('.alert') && this.container.querySelector('.submit-fail'))
            this.container.querySelector('.submit-fail').remove()
    
        if (!this.container.querySelector('.alert') || !this.container.querySelector('.submit-fail'))
            return this.request(this.submit)
    }

    handleAlert (field, emptyText, wrongText=false) {
    
        if (!field.value && !field.classList.contains('empty')) {
            let el = document.createElement('p')
            el.className = 'alert'
            el.innerHTML = emptyText
            field.classList.add('empty')
    
            if (field.classList.contains('failed')) {
                field.classList.remove('failed')
                field.parentElement.querySelector('.alert').remove()
            }
            
            return field.parentElement.appendChild(el)
        }
    
        if (field.value && field.classList.contains('empty')) {
            field.classList.remove('empty')
            field.parentElement.querySelector('.alert').remove()
        }
    
        if (!wrongText)
            return
    
        let regex
    
        switch (field.type) {
            case 'email':
                regex = /[^\s@]+@[^\s@]+\.[^\s@]+/;
                break;
            default:
                break;
        }
    
        if (regex.test(field.value) && field.classList.contains('failed')) {
    
            field.classList.remove('failed')
            return field.parentElement.querySelector('.alert').remove()
        }
    
        if (field.value && !regex.test(field.value) && !field.classList.contains('failed')) {
    
            field.classList.add('failed')
            let el = document.createElement('p')
            el.className = 'alert alert_failed'
            el.innerHTML = wrongText
            return field.parentElement.appendChild(el)
        }
    }

    request () {

        let data = new FormData(this.container)
    
        const xhr = new XMLHttpRequest()
    
        if (!xhr) return
    
        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status === 200) {
    
                    let response = document.createElement('div'),
                        msg      = document.createElement('p')
    
                    response.className = 'response-success wrapper-tn'
                    msg.innerHTML = 'Merci, votre message a bien été envoyé. Nous vous recontactons très bientôt.'
                    response.appendChild(msg)
                    this.container.after(response)
                    response.focus()
                    this.container.remove()
                } else {
                    let response = document.createElement('p')
                    response.className = 'submit-fail'
                    response.innerHTML = 'Nous sommes navré mais une erreur interne est survenue. Merci de réessayer plus tard.'
                    
                    this.submit.parentElement.appendChild(response)
                }
            }
        }
    
        xhr.open('POST', this.container.getAttribute('action'), true)
        xhr.setRequestHeader('X-Requested-with', 'xmlhttprequest');
        xhr.send(data)
    
    }
}

export { ContactForm }