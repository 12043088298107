export default () => {
    
    if (!window.XMLHttpRequest && !window.ActiveXObject)
        return alert('Votre navigateur n\'est pas assez récent…')
    
    let xhr;

    if (window.XMLHttpRequest)
        return xhr = new XMLHttpRequest()
    
    try {
        xhr = new ActiveXObject('Msxml2.XMLHTTP')
    } catch (e) {
        xhr = new ActiveXObject("Microsoft.XMLHTTP")
    }

    return xhr
}