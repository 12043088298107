import localStorageInit from './module/localstorage';
import sliderAndTabInit from './module/sliderAndTab';
import * as _account from './module/account';
import * as _basket from './module/basket';
import * as _scroll from './module/scroll';
import * as _contact from './module/form';
import * as _polyfill from './module/polyfill';

(function () {

	'use strict';

	let body = document.body,
		i;

	_polyfill.useSvg()
	_polyfill.useFind()
	_polyfill.useArray()
	_polyfill.useRemove()

	/**
	 * Init Imported function
	 */
	localStorageInit(window, document)

	sliderAndTabInit('slideshow', 'slideshow-slide', 'slideshow-controller', true);
	sliderAndTabInit('single-product-thumbnail', 'single-product-thumb', 'single-product-control');
	sliderAndTabInit('tab-container', 'tab-panel', 'tab-controller');

	if (document.querySelector('.header--home'))
		_scroll.scrollHeader()

	/* ------------------------------------------------------------------------- */

	/**
	* Open navigation and trigger burger
	* @param <Element>
	*/

	const openMenu = (burger) => {
		burger.classList.toggle('trigger')
		body.classList.toggle('overflow-y')
		document.querySelector('.menu-container').classList.toggle('trigger')
		document.querySelector('.header-container').classList.toggle('trigger')
	}

	/* ------------------------------------------------------------------------- */

	/**
	* Rate
	* @param <Element>
	*/

	const rate = (el) => {

		let target  = el,
			prev    = [],
			element = el.parentElement.children,
			lng     = element.length,
			res     = 0;
		
		while (el.previousElementSibling)
			prev.push(el = el.previousElementSibling);

		for (i=0; i<lng; i++) {

			if (prev[i]) {
				prev[i].classList.add('trigger')
				res++
			} else {
				element[i].classList.remove('trigger')
			}
		}

		res++
		target.classList.add('trigger')
		document.querySelector('.user-rate-result').setAttribute('value', res)
		return;
	}

	/* ------------------------------------------------------------------------- */

	/**
	 * Submit filter
	 * @param <Element>
	 */

	const submitFilter = (el) => el.parentElement.submit()

	/* ------------------------------------------------------------------------- */

	/**
	 * Open/Close SearchForm
	 * @param <Element>
	 */
	const openSearch = () => document.querySelector('.search-container').classList.add('trigger')
	const closeSearch = () => document.querySelector('.search-container').classList.remove('trigger')

	/* ------------------------------------------------------------------------- */

	/**
	 * Validation cookiebar
	 * @param Element 
	 */
	const cookieValidation = (el) => {
		el.parentElement.classList.add('trigger')
		document.cookie = 'accepted=accepted; expires=6048e2; path=/';
	}

	/**
	 * submit handle cookie form
	 */
	const handleCookie = function () {
		let node = document.querySelectorAll('.cookie-checkbox')

		for (i=0; i<node.length; i++) {
			node[i].addEventListener('change', function () {
				this.parentElement.parentElement.submit()
			})
		}
	}
	
	if (document.querySelector('.cookie-checkbox'))
		handleCookie()
	/* ------------------------------------------------------------------------- */

	/**
	 * instance contact form
	 * @param Element form
	 */
	if (document.querySelector('.contact-form')) {
		let contactForm = new _contact.ContactForm(document.querySelector('.contact-form'))
		contactForm.registerEvents()
	}

	/* ------------------------------------------------------------------------- */

	/**
	 * Display delivery mode tab
	 * @param Element button
	 */
	const toogleDeliveryTabMode = (el) => {

		if (el.classList.contains('trigger')) return 

		let buttons = Array.from(document.querySelectorAll('.deliverymode__opt'))

		document.querySelector('.deliverymode__opt.trigger').classList.remove('trigger')
		document.querySelector('.deliverymode__content.trigger').classList.remove('trigger')

		el.classList.add('trigger')
		document.querySelectorAll('.deliverymode__content')[buttons.indexOf(el)].classList.add('trigger')

		document.querySelector('input[name="deliverymode"]').value = buttons.indexOf(el)

		document.querySelector('input[name="adress"]').value = ''
		document.querySelector('input[name="adress_bis"]').value = ''
		document.querySelector('input[name="code_postal"]').value = ''
		document.querySelector('input[name="city"]').value = ''
	}
	
	/* ------------------------------------------------------------------------- */
	
	/**	
	 * Scroll Reveal
	 */
	// IE Compatibility
	// if (document.querySelector('[data-scroll]'))
	// 	_scroll.scrollReveal()

	body.addEventListener('click', (e) => {

		let el = e.target;

		if (el.classList.contains('open-menu'))
			return openMenu(el);

		if (el.classList.contains('password-clear'))
			return _account.clearPassword(el);

		if (el.classList.contains('rate-action'))
			return rate(el);

		if (el.classList.contains('basket-action'))
			return _basket.basketAction(el)

		if (el.classList.contains('account-modifier'))
			return _account.accountModifier(el)

		if (el.classList.contains('submit-qty'))
			return _basket.changeQuantity(e, el)
		
		if (el.classList.contains('filter-label'))
			return submitFilter(el)

		if (el.classList.contains('search-trigger'))
			return openSearch()
		
		if (el.classList.contains('search-close'))
			return closeSearch()

		if (el.classList.contains('add-to-basket') || el.classList.contains('submit-basket')) {
			e.preventDefault()
			return _basket.addToBasket(el)
		}

		if (el.classList.contains('alertbox'))
			return _basket.removeAlertBox(el)

		if (el.classList.contains('getdelivery')) {
			e.preventDefault()
			return _account.getDelivery(el)
		}

		if (el.classList.contains('cookie-accept'))
			return cookieValidation(el)

		if (el.classList.contains('deliverymode__opt'))
			return toogleDeliveryTabMode(el)

	});

	body.addEventListener('keyup', (e) => {
		let el = e.target;

		if (el.classList.contains('password-input'))
			_account.checkPassword(el);
	})

	let offsetProducts = 10,
		ajaxReady      = true,
		loader         = document.querySelector('.loader')

	window.addEventListener('scroll', (e) => {

		if (document.querySelector('.header--home'))
			_scroll.scrollHeader()

		if (!document.querySelector('.grid__product'))
			return 

		let DOMHeight 	    = document.documentElement.scrollHeight,
			footerHeight    = document.querySelector('.footer-container').clientHeight,
			containerHeight = DOMHeight - footerHeight,
			currentHeight   = body.offsetHeight + window.pageYOffset

			if (!ajaxReady)
				return 

		if (currentHeight === containerHeight || currentHeight > containerHeight) {
			ajaxReady = false

			loader.classList.add('trigger')

			let container = document.querySelector('.grid__product'),
				url       = '/more/'

			url += container.dataset.type + '/' + container.dataset.brand + '/' + offsetProducts

			const xhr = new XMLHttpRequest()
			xhr.open('GET', url, true)
			xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
			xhr.send()
			xhr.onreadystatechange = function () {
				if (xhr.readyState === XMLHttpRequest.DONE) {
					if (xhr.status === 200) {
						let res = document.createElement('div')
						res.innerHTML = xhr.responseText

						loader.classList.remove('trigger')

						if (!res.querySelector('.grid__product'))
							return 
						
						sessionStorage[url] = container.innerHTML += res.querySelector('.grid__product').innerHTML
						ajaxReady = true
					} else {
						console.log('error xhr')
					}
				}
			}

			offsetProducts += 5
		}
			
	})
	
})();