import getXMLHttpRequest from './xhr';

const basketAction = (el) => {

    let hidden = el.parentElement.parentElement.querySelector('input[name="qty"]'),
        value = Number(hidden.getAttribute('value'));

    if (el.classList.contains('low') && value > 0)
         value = value - 1;

    if (el.classList.contains('up'))
        value = value + 1;

    hidden.setAttribute('value', value)
    document.querySelector('.single-product-number').innerHTML = value

}

const changeQuantity = (e, el) => {

    e.preventDefault()

    let container = document.querySelector('form.tunnel-basket'),
        input     = el.parentElement.nextElementSibling,
        value     = input.getAttribute('value'),
        data      = el.getAttribute('data-count')

    if (value > 0)
        input.setAttribute('value', Number(value) + Number(data))

    container.submit()
}

const addToBasket = (el) => {
    let xhr = getXMLHttpRequest(),
        btn = el,
        parents = [];
    
    while (el.parentElement)
        parents.push(el = el.parentElement)
    
    let form = parents.find((element) => {
        return element.hasAttribute('action')
    })

    let quantity   = form.querySelector('input[name="qty"]').value,
        _token     = form.querySelector('input[name="_token"]').value,
        basketIcon = document.querySelector('.header-access .number-product'),
        basketIconHTML = basketIcon.innerHTML
    
    xhr.onreadystatechange = () => {
        
        if (xhr.readyState === 4 && (xhr.status === 200 || xhr.status === 0)) {
            basketIcon.innerHTML = (basketIconHTML.trim()) ? +basketIconHTML + (+quantity) : 1

            let circle        = document.createElementNS('http://www.w3.org/2000/svg', 'circle'),
                path          = document.createElementNS('http://www.w3.org/2000/svg', 'path'),
                svg           = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
                alertbox      = document.createElement('div'),
                alertboxInner = document.createElement('div'),
                paragraph     = document.createElement('p'),
                timeout;

            circle.setAttribute('cx', 15.4)
            circle.setAttribute('cy', 15.5)
            circle.setAttribute('r', 15)
            circle.setAttribute('fill', '#2f529e')
            path.setAttribute('fill', '#fff')
            path.setAttribute('d', 'M12.2 19.3l-4-4.1-1.4 1.4 5.4 5.4 11.7-11.6L22.5 9z')
            svg.setAttribute('viewBox', '0 0 30.7 31')
            svg.appendChild(circle)
            svg.appendChild(path)

            alertbox.className = 'alertbox'
            alertboxInner.className = 'alertbox-basket'
            paragraph.innerHTML = 'Produit(s) ajouté(s) au panier'

            alertboxInner.appendChild(svg)
            alertboxInner.appendChild(paragraph)
            alertbox.appendChild(alertboxInner)
            document.body.appendChild(alertbox)
            alertbox.style.animation = 'alertBox 2s forwards';

            timeout = window.setTimeout(() => {
                if (alertbox) alertbox.remove()
            }, 2250)
            
            // return alert('Votre produuit est ajouté')
        }
    }

    xhr.open('POST', form.action, true)
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.setRequestHeader("X-CSRF-TOKEN", _token);
    xhr.send("qty=" + quantity + "&_token=" + _token);
    
}

const removeAlertBox = (el) => {
    el.remove()
}

export {basketAction, changeQuantity, addToBasket, removeAlertBox}